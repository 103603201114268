@import "./reset.css";

#root {
	height: 0;
	line-height: 1.2;
}

a {
	color: inherit;
	text-decoration: inherit;
}

:root {
	--red: #d1373f;
	--dark: #2f3d47;
	--darkGray: #c6c6c6;
}

body,
textarea {
	font-family: "Open Sans", sans-serif;
	color: var(--dark);
	background: #f5f5f5;
}

textarea {
	border: none;
	background: none;
}

h3 {
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 10px;
}

h4 {
	font-family: "Raleway", sans-serif;
	font-weight: 700;
	font-size: 16px;
}

button button:not([class^="MuiIconButton"]) {
	font-family: "Open Sans", sans-serif !important;
	width: 100%;
}

.MuiFormControl-root {
	width: 100%;
}

.clickable {
	cursor: pointer;
}

.webix_tree {
	height: auto !important;
	border: none !important;
}

#kanbanStandalone {
	height: calc(100% - 75px) !important;
}

#kanban,
.webix_drag_zone.webix_kanban_drag_zone {
	.webixComponent {
		height: 100%;
	}

	.webix_kanban {
		background: none;
	}

	.webix_view.webix_kanban.webix_layout_space {
		border: none;
	}

	.webix_kanban_footer {
		display: none;
	}

	div > div > div:nth-child(1) > div.webix_accordionitem_header {
		color: #16b1f0;
	}

	div > div > div:nth-child(2) > div.webix_accordionitem_header {
		color: #ef5f2f;
	}

	div > div > div:nth-child(3) > div.webix_accordionitem_header {
		color: #390a4e;
	}

	div > div > div:nth-child(4) > div.webix_accordionitem_header {
		color: #f2a524;
	}

	div > div > div:nth-child(5) > div.webix_accordionitem_header {
		color: #d1383f;
	}

	div > div > div:nth-child(6) > div.webix_accordionitem_header {
		color: #7faf41;
	}

	div > div > div:nth-child(7) > div.webix_accordionitem_header {
		color: #764899;
	}

	div > div > div:nth-child(8) > div.webix_accordionitem_header {
		color: #c6c6c6;
	}

	div > div > div:nth-child(9) > div.webix_accordionitem_header {
		color: #2f3d47;
	}

	div[webix_ai_id="$accordionitem8"] > .webix_accordionitem_button {
		transform: rotate(180deg);
	}

	.webix_accordionitem_button {
		color: var(--red);
	}

	.webix_accordionitem_header {
		background: #f5f5f5;
	}

	.webix_kanban_list_content {
		border-left: 3px solid var(--red);
	}

	.webix_kanban_user_avatar:hover {
		box-shadow: var(--red) 0 0 2px 1px;
	}
}

.dashboardKanban {
	.webixComponent {
		height: calc(100% - 40px) !important;
	}
}

.geItem:nth-child(4) {
	cursor: not-allowed;
}

.geItem:nth-child(4):hover {
	background: none;
}

.geItem:nth-child(5) {
	cursor: not-allowed;
}

.geItem:nth-child(5):hover {
	background: none;
}

.webix_tree_folder::before {
	//content: "";
	color: #2f3d47;
}
.webix_tree_folder_open::before {
	//content: "";
	color: #2f3d47;
}

.webix_tree_close::before,
.webix_tree_open::before {
	color: #2f3d47;
}

.webix_tree_item {
	color: #2f3d47;
	font-family: "Open Sans", sans-serif;
}

.webix_qb_add_group,
.webix_qb_add_rule,
.webix_icon.wxi-trash {
	color: var(--red) !important;
}

.webix_active {
	background-color: var(--red) !important;
}

.webix_list_item.webix_selected {
	box-shadow: inset 2px 0 var(--red);
}

.comments {
	textarea[data-focusvisible-polyfill],
	.webix_popup_button {
		border: 1px solid var(--red) !important;
	}
}
.webix_primary .webix_button {
	background-color: var(--red) !important;
}
.webix_popup_button {
	color: var(--red);
}

.webix_popup_button.confirm {
	background: var(--red) !important;
	color: white;
}

.webix_alert .webix_popup_title,
.webix_confirm .webix_popup_title,
.webix_alert .webix_popup_text,
.webix_confirm .webix_popup_text {
	box-shadow: inset 0 4px var(--red);
}

.webix_comments_mention {
	color: var(--red);
}

#querybuilder,
#bitCases {
	.webix_qb_add {
		button {
			width: auto;
		}
	}

	.webix_el_richselect .webix_inp_static:focus,
	.webix_el_box > input:focus {
		border: 1px solid var(--red) !important;
	}

	.webix_view {
		background: none;
		.webix_layout_space {
			background: none;
			.webix_qb_wrap {
				background: none;
			}
		}
	}

	.webix_qb_wrap .webix_qb_wrap {
		background: white;
		border: 1px solid #303030 !important;
		border-left: 1px solid #303030 !important;
		border-radius: 5px;
		box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
			0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	}
}

.MuiAccordionPanelSummary-content {
	font-weight: 700;
	font-family: "Raleway", sans-serif;
}

.paperPadding {
	padding: 10px;
}

.webix_popup {
	z-index: 1301 !important;

	.webix_win_body > .webix_list {
		//max-height: 250px;
		//overflow: auto;
	}
}

ul {
	display: block;
	list-style-type: disc;
	margin-top: 1em;
	margin-bottom: 1em;
	margin-left: 0;
	margin-right: 0;
	padding-left: 40px;
}

ol {
	display: block;
	list-style-type: decimal;
	margin-top: 1em;
	margin-bottom: 1em;
	margin-left: 0;
	margin-right: 0;
	padding-left: 40px;
}

li {
	display: list-item;
}

.bit,
.lme,
.passthrough {
	background: #dedede;
	border-radius: 5px;
	color: #ef5f2f;
	cursor: pointer;
}

.hover:hover {
	background: #f5f5f5;
}

.lme {
	color: #d1383f;
}

.passthrough {
	color: #390a4e;
}

.prototype {
	div[dir="auto"] > span {
		//.bubble span {
		margin-left: 0 !important;
	}
	span[class*="r-marginLeft-"] {
		margin-right: 0 !important;
	}
}

.popoverIcon:hover {
	color: #f2a524;
	cursor: pointer;
}

.webix_list {
	.webix_list_item {
		height: auto !important;
		overflow: auto !important;
	}
	.webix_scroll_cont {
		max-height: 100%;
		overflow-y: auto;
	}
}
div[view_id^="$suggest"] {
	.webix_list_item {
		line-height: 1.5;
	}
}

.MuiFab-root {
	background: var(--red) !important;
}

.MuiFab-label {
	color: white !important;
}

.previewContent {
	strong {
		font-weight: 700;
	}

	em {
		font-style: italic;
	}

	u {
		text-decoration: underline;
	}

	a {
		color: blue;
		text-decoration: underline;
	}
}
.react-trello-board {
	height: calc(100% - 55px) !important;

	.react-trello-card {
		header {
			padding-bottom: 0;
			margin-bottom: 0;
			span {
				cursor: pointer;
				font-weight: 400 !important;
			}
		}
	}
}

.kanbanPage {
	.react-trello-board {
		height: calc(100% - 12px) !important;
	}
}

//MZM 4-1-21 I hate CSS. Lets not talk about it.
.reactSelectScroll .MuiDialogContent-root,
.reactSelectScroll .MuiDialog-paper {
	overflow-y: visible !important;
}

.tailoredImage {
	max-width: 100%;
}

.dashboardKanban {
	.smooth-dnd-container {
		height: 370px;
	}
}

.kanbanPage {
	.smooth-dnd-container {
		height: calc(100vh - 80px);
	}
}

.dragSelected {
	background: rgba(0, 0, 0, 0.1);
}
